<template>
	<div id="tu-faqs">
		<el-row :gutter="20">
			<el-col :span="16" class="left">
				<div class="search" v-if="!!params.search_text || !!params.tag">
					<div class="text" v-if="params.search_text">{{ $t('search') }}"{{ params.search_text }}"</div>
					<div class="text" v-else>"{{ params.tag }}"{{ $t('Content') }}</div>
					<div class="close" @click="clearSearchText">
						<el-icon style="font-size: 24px;cursor: pointer;"><i class="fa fa-close"></i></el-icon>
					</div>
				</div>
		    	<div class="tab" v-else>
	    			<div :class="{active: !params.category_id }" @click="changeFaqsCategory('')">{{ $t('Faqs') }}</div>
	    			<div v-for="item, index in faqsMenuData" :key="index" 
	    				@click="changeFaqsCategory(item.id)"
	    				:class="{active: item.id == params.category_id}">{{ item.title }}</div>
		    	</div>
		    	<div class="warp">
		    		<div class="item" v-for="item, index in lists" :key="index">
		    			<div class="item__image">
		    				<el-image v-if="item.cover_image" style="border-radius: 9px;" :src="item.cover_image" fit="contain" />
		    				<div v-else class="default">Tutoredin</div>
		    			</div>
		    			<div class="item__text">
		    				<div @click="handleDetail(index, item)">
			    				<div class="title">{{ item.title }}</div>
			    				<div class="desc">{{ item.desc }}</div>
		    				</div>
		    				<div class="tags-date">
		    					<div>
									<el-button v-for="tagItem, tagIndex in item.tags" :key="tagIndex" size="small" round @click="$router.push({path: '/faqs', query: {tag: tagItem}})">{{ tagItem }}</el-button>
		    					</div>
		    					<div style="color: #81838c;">{{ handleDateShow(item.create_time) }}</div>
		    				</div>
		    			</div>
		    		</div>
		    		<div style="text-align: center;">
		    			<el-pagination layout="prev, pager, next" :total="params.total" hide-on-single-page 
		    				:page-size="20"
		    				@current-change="handlePageChange" 
		    				@prev-click="handlePageChange" 
		    				@next-click="handlePageChange" />
		    		</div>
		    	</div>
			</el-col>
			<el-col :span="8" class="right">
				<FaqsRight />
			</el-col>
		</el-row>
	</div>
</template>
<script>
import FaqsApi from '@/api/faqs'
import TimeControl from '@/utils/time-control'
import { mapActions, mapGetters } from 'vuex'
import FaqsRight from './components/FaqsRight';

export default {
	name: "faqs",
	components: {
		FaqsRight
	},
	data() {
		return {
			loading: true,
			params: {
				search_text: '',
				tag: '',
				category_id: '',
				page: 1,
				total: 0
			},
			lists: [],
			setInt: null
		}
	},
	computed: {
		...mapGetters([
			'faqsMenuData'
		])
	},
	created() {
		this.params.search_text = this.$route.query.keyword;
		this.params.tag = this.$route.query.tag;
		this.getApiListData();
		this.$store.dispatch('GetFaqsMenu')
	},
	watch: {
		"$route.query.keyword": function() {
			if(this.params.search_text != this.$route.query.keyword) {
				this.params.search_text = this.$route.query.keyword;
				this.getApiListData(1);
			}
		},
		"$route.query.tag": function() {
			if(this.params.tag != this.$route.query.tag) {
				this.params.tag = this.$route.query.tag;
				this.getApiListData(1);
			}
		}
	},
	methods: {
		...mapActions({
			GetFaqsMenu: 'GetFaqsMenu'
		}),
		handleDetail(index, row) {
			let rowId = row.id || 0;
			if(!!!rowId) {
				this.$message.error(this.$t('exception.request'));
				return false;
			}
			let isUrl = row.is_url;
			if(isUrl == 1) {
				let url = row.url;
				window.open(url, '_blank');
				return ;
			}
			this.$router.push({ path: `/faqs/${rowId}` })
		},
		handleDateShow(value) {
			return TimeControl.getFormatTime(value, 'YYYY-MM-DD')
		},
		handlePageChange(page) {
			this.params.page = page || 1;
			this.getApiListData();
		},
		getApiListData() {
			let params = this.params;
			FaqsApi.index(this.params).then((res) => {
				this.lists = res.data
				this.params.total = res.meta.pagination.total
			});
		},
		clearSearchText() {
			this.$router.push({path: '/faqs'});
		},
		changeFaqsCategory(value) {
			if(this.setInt) {
				clearTimeout(this.setInt);
			}
			this.setInt = setTimeout(() => {
				this.params.category_id = value || "";
				this.handlePageChange(1);
			}, 500);
			
		}
	}
}
</script>
<style lang="less">
#tu-faqs {
	margin: 30px 0;

	.left {
		.search {
			background: #e7eefe;
		    border-radius: 9px;
		    margin: 0 45px;
		    border: unset;
		    padding: 18px 10px;
		    display: flex;
		    align-items: center;
		    justify-content: space-between;

			.text {
				color: #000;
				font-weight: 500;
				font-size: 24px;
				line-height: 34px;
			}

			.close {

			}
		}

		.tab {
			display: flex;
			justify-content: center;
			flex-direction: row;
			font-size: 22px;
			color: #000;

			& > div {
				cursor: pointer;
				margin: 5px 15px;

				&.active {
					font-weight: 500;
					border-bottom: 3px solid #000;
				}
			}
		}

		.warp {
			padding: 0px 45px;
			margin-top: 45px;

			.item {
				cursor: pointer;
				display: flex;
				margin-bottom: 65px;
				&__image {
					width: 168px;
					height: 115px;
					flex-shrink: 0;
					border-radius: 9px;

					.default {
						text-align: center;
						font-size: 22px;
						border: 2px solid var(--el-color-primary);
						height: 115px;
						line-height: 115px;
						border-radius: 9px;
						color: var(--el-color-primary);
						font-weight: 600;
					}
				}

				&__text {
					margin-left: 36px;
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					line-height: 1.3;

					.title {
						font-size: 22px;
						font-weight: 500;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						line-clamp: 1;
						overflow: hidden;
						-webkit-box-orient: vertical;
					}

					.desc {
						margin-top: 5px;
						font-size: 15px;
						color: #81838c;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						overflow: hidden;
						-webkit-box-orient: vertical;
					}

					.tags-date {
						display: flex;
					    flex-direction: row;
					    align-items: center;
					    justify-content: space-between;
					}
				}
			}
		}
	}
}
</style>